export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";


export const LOGIN_WITH_OTP = "LOGIN_WITH_OTP";
export const LOGIN_WITH_OTP_SUCCESS = "LOGIN_WITH_OTP_SUCCESS";
export const LOGIN_WITH_OTP_FAIL = "LOGIN_WITH_OTP_FAIL";


export const FORGOT_PASS = "FORGOT_PASS";
export const FORGOT_PASS_SUCCESS = "FORGOT_PASS_SUCCESS";
export const FORGOT_PASS_FAIL = "FORGOT_PASS_FAIL";

export const RESET_PASS = "RESET_PASS";
export const RESET_PASS_SUCCESS = "RESET_PASS_SUCCESS";
export const RESET_PASS_FAIL = "RESET_PASS_FAIL";

export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";
